import './App.css';
import Carousel from "./carousel";
import {BSUHeader} from "./BSUHeader/BSUHeader.tsx";
import {BSUFooter} from "./BSUFooter/BSUFooter.tsx";

function App() {

    return (
        <>
            <BSUHeader/>
            <div className={"carousel-outer-box"}>
                <h1>Honors College Timeline</h1>
                <p className={"carousel-outer-box-p"}>The Honors College has grown with Ball State University to expand the minds of our students.</p>
                <Carousel/>
                <a className={"return-button"} href={"https://www.bsu.edu/academics/collegesanddepartments/honorscollege"}>RETURN TO HONORS COLLEGE</a>
            </div>
            <BSUFooter hideLogo={true}/>
        </>
    );
}

export default App;
