import React from "react";
import exitIcon from "../src/Assets/ExitIcon.svg"

const SlidePopout = (props) => {

    return (
        <div className={props.className} onClick={props.onClick}>
            <img className={"exit-button"} src={exitIcon} alt={"exit button"} onClick={props.close}/>
            <h2 className={"popout-year"}>{props.year}</h2>
            <img className={"popout-img"} alt={"pic"} src={props.imageSrc}></img>
            <div className={"popout-p"}>
                <p>{props.paragraph}</p>
                <p className={"popout-quit"} onClick={props.close}>Close full view</p>
            </div>
            <div className={"popout-caption-box"}>
                <p className={"popout-caption"}>{props.caption}</p>
            </div>
        </div>
    );

};

export default SlidePopout;
