import React, {useEffect, useState} from "react";

import "./Carousel.css";
import Arrow from "./Arrow";
import {useMediaQuery} from "react-responsive";
import Slide from "./Slide";
import slide_info from "./slides-info";
import SlidePopout from "./Slide-Popout";
import DateTracker from "./DateTracker";

const Carousel = () => {

    //TODO:
    //Fix alignment with left arrow and carousel, I don't know why they are stacking on each other instead of being next to one another
    //figure it out.

    const [activeIndex, setActiveIndex] = useState(0);
    const isMobile = useMediaQuery({query: `(max-width: 650px)`});
    const [newIndexSubtraction, setNewIndexSubtraction] = useState(4)
    const [translateDistance, setTranslateDistance] = useState(25)

    const [initialPopout, setInitialPopout] = useState(false)

    const [popout, setPopout] = useState(false)
    const [popoutImage, setPopoutImage] = useState()
    const [popoutYear, setPopoutYear] = useState()
    const [popoutParagraph, setPopoutParagraph] = useState()
    const [popoutCaption, setPopoutCaption] = useState()
    const [popoutIndex, setPopoutIndex] = useState()

    const [touchPositionX, setTouchPositionX] = useState(null)


    const handlePopout = (paragraph, image, year, caption, index) => {
        setPopout(true)
        setPopoutIndex(index)
        setInitialPopout(true)
        setPopoutParagraph(paragraph)
        setPopoutYear(year)
        setPopoutImage(image)
        setPopoutCaption(caption)
    }

    const handleClosePopout = () => {
        setPopout(false)
    }

    useEffect(() => {
        if (isMobile) {
            setNewIndexSubtraction(1)
            setTranslateDistance(100)
        } else {
            setNewIndexSubtraction(4)
            setTranslateDistance(25)
        }
    }, [isMobile])

    const updateIndex = (newIndex) => {
        if (popout) {
            return
        }
        if (newIndex < 0) {
            newIndex = slide_info.length - newIndexSubtraction;
        } else if (newIndex >= slide_info.length - (newIndexSubtraction - 1)) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        if (touchDown) setTouchPositionX(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPositionX

        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            updateIndex(activeIndex + 1)
        }

        if (diff < -5) {
            updateIndex(activeIndex - 1)
        }

        setTouchPositionX(null)
    }

    const calculateEnd = () => {
        if (popout) {
            return null
        } else if (!isMobile) {
            return activeIndex + 4
        } else {
            return null
        }
    }
    return (
        <div className={"carousel-holder-holder"}>
            <div className="carousel-holder">
                <Arrow className={"leftArrow"} right={false} hidden={popout} onClick={() => {
                    updateIndex(activeIndex - 1);
                }}/>
                <div className="carousel" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div
                        className="inner"
                        style={{transform: `translateX(-${activeIndex * translateDistance}%)`}}
                    >
                        {slide_info.map((slide, index) => {
                            return (
                                <Slide index={index} currentIndex={activeIndex} caption={slide.caption}
                                       paragraph={slide.paragraph} imageSrc={slide.src} year={slide.year}
                                       onClick={!isMobile ? () => {
                                           handlePopout(slide.paragraph, slide.src, slide.year, slide.caption, index)
                                       } : null}/>
                            );
                        })}
                    </div>
                </div>
                {initialPopout &&
                    <SlidePopout className={popout ? "popout" : "hidden-popout"} paragraph={popoutParagraph}
                                 imageSrc={popoutImage} year={popoutYear} caption={popoutCaption}
                                 close={handleClosePopout}/>}
                <Arrow className={"rightArrow"} right={true} hidden={popout} onClick={() => {
                    updateIndex(activeIndex + 1);
                }}/>
            </div>
            <DateTracker start={!popout ? activeIndex + 1 : popoutIndex + 1} end={calculateEnd()}
                         total={slide_info.length}/>
        </div>
    );
};

export default Carousel;
