import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";

const Slide = (props) => {

    const [readMoreShowing, setReadMoreShowing] = useState(false)
    const [clicked, setClicked] = useState(false)
    const isMobile = useMediaQuery({query: `(max-width: 650px)`});
    const handleShowExtraDesktop = props.onClick

    useEffect(() => {
        if (isMobile) {
            setReadMoreShowing(true)
        } else {
            setReadMoreShowing(false)
        }
    }, [isMobile])

    useEffect(()=>{
        if(isMobile && props.index !== props.currentIndex){
            setClicked(false)
        }
    },[isMobile,props.index,props.currentIndex])

    const handleMouseEnter = () => {
        if (isMobile) {
            return
        }
        setReadMoreShowing(true)
    }

    const handleMouseLeave = () => {
        if (isMobile) {
            return
        }
        setReadMoreShowing(false)
    }

    return (
        <div className="carousel-item">
            <div className="slide" onClick={!isMobile ? handleShowExtraDesktop : null}
                 onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img className={"slide-img"} alt={"pic"} src={props.imageSrc}></img>
                <h2 className={"year"}>{props.year}</h2>
                <p className={isMobile && clicked ? "mobile-expanded" : "slide-p"}>{props.paragraph}</p>
                <p className={readMoreShowing ? "read-more-showing" : "read-more-hidden"} onClick={isMobile ? () => {
                    setClicked(!clicked)
                } : null}>{clicked ? "click to collapse" : "click to show more"}</p>
            </div>
        </div>
    );
};

export default Slide;
