import rightArrow from "./Assets/right-arrow.svg"
import leftArrow from "./Assets/left-arrow.svg"

import {useEffect, useState} from "react";

const Arrow = (props) => {
    const {onClick, right } = props;
    const [class_name,setClass_name] = useState("-arrow");
    const side = right ? "right" : "left"

    useEffect(()=>{
        setClass_name(right ? "right-arrow" : "left-arrow")
    },[right])

    const changeArrowColor = () => {
        setClass_name( side + "-arrow-hover")
    }

    const resetArrowColor = () => {
        setClass_name(side + "-arrow")
    }

    return (
        <div
            className={ right ? "right-arrow-box" : "left-arrow-box"}
            style={{display: "block", background: "#F5F5F5" }}
            onClick={onClick}
            onMouseEnter={changeArrowColor}
            onMouseLeave={resetArrowColor}
        >
            {!props.hidden && <img className={class_name} src={right ? rightArrow : leftArrow} alt={"arrow button"}/>}
        </div>
    );
}

export default Arrow