function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
}
const imagesFromFile = importAll(require.context('./Assets', false, /\.(png|jpe?g|svg)$/));

const slide_info = [
    {
        year: "1955",
        src: imagesFromFile['1955.jpg'],
        alt: "Image 1",
        paragraph: "Dr. Richard W. Burkhardt, dean of faculties at Ball State Teachers College, sent a group of faculty to a workshop at the University of Minnesota to study the subject of Honors education.",
        caption: "Dr. Richard W. Burkhardt"
    },
    {
        year: "1955-1956",
        src: imagesFromFile['1955-56.jpg'],
        alt: "Image 2",
        paragraph: "Dean Burkhardt established a steering committee of faculty and charged them with identifying means of establishing an Honors program at Ball State.  The establishment of an Honors program had the support of Dr. John R. Emens, the president of Ball State.",
        caption: "John R. Emens"
    },
    {
        year: "1958",
        src: imagesFromFile['1958.jpg'],
        alt: "Image 3",
        paragraph: "Dean Burkhardt appointed Dr. Victor B. Lawhead, dean of undergraduate programs, to formulate an Honors curriculum at Ball State.  As Lawhead later explained, “The main objective of Honors [at Ball State] is to provide a group of able students the best possible education that this institution can offer.”  The Honors curriculum that Lawhead devised covered all four of the undergraduate years and stressed courses in the liberal arts, humanities, and social sciences.",
        caption: "Victor B. Lawhead"
    },
    {
        year: "1959",
        src: imagesFromFile['1959.jpg'],
        alt: "Image 4",
        paragraph: "In the fall academic quarter, the first class of Honors students, numbering ninety, enrolled at Ball State.  The Honors program had a core curriculum and an Honors Committee, comprised of faculty, to advise it.  Dr. Jerome Fallon, associate dean and director of instructional services, was the first administrator to oversee the Honors program.",
        caption: "Jerome Fallon"
    },
    {
        year: "1964",
        src: imagesFromFile['1964.jpg'],
        alt: "Image 5",
        paragraph: "Following Jerome Fallon’s departure from Ball State, Victor Lawhead assumed responsibility for the Honors program.  Lawhead established the Honors Student Advisory Council in 1965, giving students a role in the program’s governance.  The Student Honors Advisory Council consisted of twelve students, four from each of the undergraduate classes. Honors continued to grow at Ball State, from 90 students in 1959, to 309 by 1965, to 800 in 1967.",
        caption: "Recent photo of members of the Honors Student Advisory Council"
    },
    {
        year: "1967",
        src: imagesFromFile['1967.jpg'],
        alt: "Image 6",
        paragraph: "Dr. Alexander (Sandy) MacGibbon, an English professor, was appointed as the first full-time director of the Honors program.  MacGibbon expanded the number of Honors colloquia as well as the number of Ball State faculty who taught Honors courses.  He also obtained a University-owned house at 203 N. College, which subsequently became the Honors House, the first residence on campus for the Honors program.",
        caption: "Alexander MacGibbon"
    },
    {
        year: "1970",
        src: imagesFromFile['1970.jpeg'],
        alt: "Image 7",
        paragraph: "Dr. C. Warren Vander Hill, a History professor, succeeded Sandy MacGibbon as director of the Honors program.  Vander Hill expanded the Honors core curriculum by adding HONR 199, Inquiries in Contemporary American Civilization, in 1974-1975, and BIO 199, Human Genetics and Bio-ethical Decision Making, in 1977.",
        caption: "C. Warren Vander Hill, a professor of History, and  Dr. Thomas Mertens and Dr. Jon Hendrix, professors of Biology who taught the Honors science course."
    },
    {
        year: "1974-1975",
        src: imagesFromFile['1974-75.jpeg'],
        alt: "Image 8",
        paragraph: "The Honors program and Ball State’s Housing office agreed to place Honors students in Botsford Hall and Swinford Hall in the Johnson residence hall complex, the first time that Honors students were given designated housing spaces.",
        caption: "Johnson Complex, Botsford Hall and Swinford Hall"
    },
    {
        year: "1976",
        src: imagesFromFile['1976.jpeg'],
        alt: "Image 9",
        paragraph: "The Honors program admitted its first class of Whitinger Scholars.  The first class consisted of sixteen academically talented students who received full-tuition scholarships.  The Whitinger Scholarships were named in honor of Ralph J. Whitinger, a distinguished Ball State alumnus who founded a certified public accounting firm in Muncie and was also instrumental in the establishment of the Ball State University Foundation.",
        caption: "Ralph J. Whitinger and first class of Whitinger Scholars"
    },
    {
        year: "1979",
        src: imagesFromFile['1979.jpeg'],
        alt: "Image 10",
        paragraph: "By the late 1970s, the Honors program had become a cohesive program with a core curriculum and Honors housing.  Warren Vander Hill proposed to the University officers and the University Senate that the name be changed from the Honors program to the Honors College.  The Senate agreed with the change and the Board of Trustees approved it in May, 1979.",
        caption: "Congressman Phil Sharp, a former Ball State political science professor, meets with Honors students at the home of English Professor Joe Trimmer and his wife Carol. right."
    },
    {
        year: "1980",
        src: imagesFromFile['1980.jpg'],
        alt: "Image 11",
        paragraph: "The offices of the Honors College moved from the Honors House at 203 N. College to the second floor of the Whitinger Business Building. (Now the Miller College of Business)  This move placed the Honors offices into closer proximity to the Honors residence halls while also giving Honors a wider visibility on campus.",
        caption: "203 N. College house"
    },
    {
        year: "1982",
        src: imagesFromFile['1982.jpeg'],
        alt: "Image 12",
        paragraph: "The Honors College instituted an Undergraduate Fellows program where students were given the opportunity to collaborate with Honors professors on their research projects. The Undergraduate Fellows program enabled Honors students to acquire valuable experience working with their professors.",
        caption: "Undergraduate Fellows"
    },
    {
        year: "1983",
        src: imagesFromFile['1983.jpg'],
        alt: "Image 12",
        paragraph: "Ball State began a student exchange program, as well as a faculty exchange program, with Westminster College near Oxford, England.   This program provided Honors students with a  distinctive study-abroad opportunity in the United Kingdom as well as a unique study-abroad opportunity for Westminster students to study in the United States.  Honors faculty members, likewise, accompanied the Ball State students to Great Britain and also participated in courses in their specialties while at Westminster. Dr. Paul Ranieri of the English Department (top, second from left) led the group. Kristen Richey, bottom, second left, later went on to become a faculty member in Psychological Science at Ball State.",
        caption: "Shown above are the Ball State Honors College students who attended Westminster College in fall, 1992."
    },
    {
        year: "1985",
        src: imagesFromFile['1985.jpeg'],
        alt: "Image 13",
        paragraph: "Dr. Arno F. Wittig, a professor of psychological science, succeeded Warren Vander Hill as director of the Honors College after Vander Hill accepted an appointment as associate provost.  Wittig was subsequently named dean (instead of director) of the Honors College.  The College continued to grow, surpassing 1,000 students for the first time.  The Honors College also added HONR 189, Honors Symposium in Global Studies, to its core curriculum.",
        caption: "Arno F. Wittig"
    },
    {
        year: "1989",
        src: imagesFromFile['1989.jpeg'],
        alt: "Image 14",
        paragraph: "The Honors College announced the Reed D. Voran Honors Distinguished Professorship which was established by friends and colleagues of Reed Voran, a distinguished attorney in Muncie and senior partner of a law firm bearing his name. The Professorship recognized Voran’s distinguished legal career, his support for Honors and for Ball State, and his love of the liberal arts.",
        caption: "(Pictured from left to right, top to bottom) Reed Voran, Patricia Keith-Spiegel, Frank Felsenstein, Alex Kaufman"
    },
    {
        year: "1996",
        src: imagesFromFile['1996.jpeg'],
        alt: "Image 15",
        paragraph: "Dr. Bruce Meyer, a professor of architecture, succeeded Arno Wittig as Honors dean after Wittig’s retirement.  In 1987, the Honors College offices moved from the Whitinger Business Building to the Richard W. Burkhardt Building after the Burkhardt Building’s extensive renovation.  Dean Meyer moved the offices, again, to Carmichael Hall to be in closer proximity to Honors housing.  On June 20, 1998, Dean Meyer died tragically from a heart attack and Arno Wittig became interim dean until the completion of a search to fill the position permanently.",
        caption: "Bruce Meyer, Carmichael Hall"
    },
    {
        year: "2000",
        src: imagesFromFile['2000.jpeg'],
        alt: "Image 16",
        paragraph: "Dr. James S. Ruebel, a professor of classics at Iowa State University, was named dean of the Honors College at Ball State.  Under Ruebel’s leadership, the Honors College flourished, growing to more than 1,500 students by 2004-2005.  Ruebel also expanded the number of study-abroad opportunities for Honors students to include programs in England, Ireland, Italy, Greece, and Vietnam.",
        caption: "James Ruebel"
    },
    {
        year: "2002",
        src: imagesFromFile['2002.jpg'],
        alt: "Image 17",
        paragraph: "The Honors College instituted its Peer Mentor program.",
        caption: "Peer Mentor Program"
    },
    {
        year: "2008",
        src: imagesFromFile['2008.png'],
        alt: "Image 18",
        paragraph: "Honors College housing moved from Botsford and Swinford Halls in the Johnson Complex to the residence halls in the De Hority Complex, which had just recently completed renovation.  Located in the center of the Ball State campus, the De Hority Halls offered a convenient route for Honors students to such classroom buildings as the Burkhardt Building, the Cooper Science complex, the Ball Communications Building, and the David Letterman Building.  A further advantage to De Hority was its closeness to Bracken Library.",
        caption: "De Hority Complex"
    },
    {
        year: "2009",
        src: imagesFromFile['2009.jpeg'],
        alt: "Image 19",
        paragraph: "On October 16, the Edmund F. and Virginia B. Ball Honors House at 1707 W. Riverside was officially dedicated.  The Ball Honors House became the permanent location of the Honors college.  Located across the street from the De Hority Complex, the Ball Honors House provided a symmetry between the living and learning experiences of Honors students at Ball State.  With the moves into the De Hority and the Ball Honors House, the Honors college now occupied a visible location within the center of the campus, a goal sought by the college for the previous four decades.",
        caption: "Virginia B. Ball and Edmund F. Ball, top. President Jo Ann M. Gora of Ball State University, left, greets at the dedication of the Edmund F and Virginia B. Ball Honors House, October 16, 2009."
    },
    {
        year: "2017",
        src: imagesFromFile['2017.jpeg'],
        alt: "Image 20",
        paragraph: "Dr. John Emert, a professor of mathematic sciences, succeeded James Ruebal as dean of the Honors College, after Dr. Ruebel’s tragic death in 2016.  Dr. Emert came to the dean’s position with extensive experience in the Honors college, first as a teacher of Honors courses and then, since 2007, as associate dean of the college.",
        caption: "John Emert and The Honors College instituted its Honors Senior Recognition Ceremony to recognize the academic achievements of its soon-to-be graduates"
    }
];
export default slide_info;